import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Landing = props => {
  const data = useStaticQuery(graphql`
    {
      landings: allContentfulLanding {
        nodes {
          id
          backgroundImage {
            file {
              url
            }
          }
          paragraph {
            paragraph
          }
          title
          linkedSection {
            title
            href
          }
        }
      }
    }
  `)
  const landing = data.landings.nodes.find(item => item.id === props.contentfulId)

  return (
    <header id="header">
      <div
        className="intro"
        style={{
          background: `url(${landing.backgroundImage.file.url})`,
        }}
      >
        <div className="overlay">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {landing.title}
                  <span></span>
                </h1>
                <br/>
                <p>{landing.paragraph.paragraph}</p>
                <a href={landing.linkedSection.href} className="btn btn-custom btn-lg page-scroll">
                  {landing.linkedSection.title}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Landing
