const contentfulComponentDict = new Map([
  ["ContentfulAbout", "components/About.jsx"],
  ["ContentfulNavBar", "components/Navigation.jsx"],
  ["ContentfulFeatures", "components/Features.jsx"],
  ["ContentfulTeamHolder", "components/Team.jsx"],
  ["ContentfulContactUs", "components/ContactUs.jsx"],
  ["ContentfulLanding", "components/Landing.jsx"],
  ["ContentfulServicesSection", "components/Services.jsx"],
  ["ContentfulQuoteSection", "components/Quotes.jsx"],
  ["ContentfulGallery", "components/Gallery.jsx"],
])

export default contentfulComponentDict
