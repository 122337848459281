import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const About = props => {
  const data = useStaticQuery(graphql`
    {
      abouts: allContentfulAbout {
        nodes {
          id
          image {
            title
            file {
              url
            }
          }
          reasons
          reasonsExtended
          paragraph {
            paragraph
          }
          title
          subtitle
        }
      }
    }
  `)
  const about = data.abouts.nodes.find(item => item.id === props.contentfulId)

  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src={`${about.image.file.url}`} className="img-responsive" alt={`${about.image.title}`} />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{about.title}</h2>
              <p>{about.paragraph.paragraph}</p>
              <h3>{about.subtitle}</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {about.reasons.map((d, i) => (
                      <li key={`${d}-${i}`}>{d}</li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {about.reasonsExtended.map((d, i) => (
                      <li key={`${d}-${i}`}> {d}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
