import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Features = props => {
  const data = useStaticQuery(graphql`
    {
      features: allContentfulFeatures {
        nodes {
          id
          title
          icons {
            title
            text
            img {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
  `)
  const feature = data.features.nodes.find(item => item.id === props.contentfulId)
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>{feature.title}</h2>
        </div>
        <div className="row">
          {feature.icons.map((icon, i) => (
            <div key={`${icon.title}-${i}`} className="col-xs-6 col-md-3">
              <img className="icon" src={icon.img.file.url} alt={icon.img.title}></img>
              <h3>{icon.title}</h3>
              <p>{icon.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Features
