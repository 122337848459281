import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Team = props => {
  const data = useStaticQuery(graphql`
    {
      teams: allContentfulTeamHolder {
        nodes {
          paragraph {
            paragraph
          }
          teamMembers {
            job
            name
            photo {
              title
              file {
                url
              }
            }
          }
          title
          id
        }
      }
    }
  `)
  const team = data.teams.nodes.find(item => item.id === props.contentfulId)

  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>{team.title}</h2>
          <p>{team.paragraph.paragraph}</p>
        </div>
        <div id="row">
          {team.teamMembers.map((member, i) => (
            <div key={`${member.name}-${i}`} className="col-md-3 col-sm-6 team">
              <div className="thumbnail">
                {" "}
                <img src={member.photo.file.url} alt={member.photo.title} className="team-img" />
                <div className="caption">
                  <h4>{member.name}</h4>
                  <p>{member.job}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Team
