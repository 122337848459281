import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Quotes = props => {
  const data = useStaticQuery(graphql`
    {
      quoteSections: allContentfulQuoteSection {
        nodes {
          quotes {
            author
            paragraph {
              paragraph
            }
            icon {
              title
              file {
                url
              }
            }
          }
          title
          id
        }
      }
    }
  `)
  const quoteSection = data.quoteSections.nodes.find(item => item.id === props.contentfulId)

  return (
    <div id="quotes">
      <div className="container">
        <div className="section-title text-center">
          <h2>{quoteSection.title}</h2>
        </div>
        <div className="row">
          {quoteSection.quotes.map((quote, i) => (
            <div key={`${quote.author}-${i}`} className="col-md-4">
              <div className="quote">
                <div className="quote-image">
                  <img src={quote.icon.file.url} alt={quote.icon.title} />
                </div>
                <div className="quote-content">
                  <p>{quote.paragraph.paragraph}</p>
                  <div className="quote-meta"> - {quote.author} </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Quotes
