import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Navigation = props => {
  const data = useStaticQuery(graphql`
    {
      navigations: allContentfulNavBar {
        nodes {
          id
          title
          links {
            href
            title
          }
        }
      }
    }
  `)

  const navigation = data.navigations.nodes.find(item => item.id === props.contentfulId)

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <div className="row">
            <a className="navbar-brand page-scroll" href="#page-top">
              {navigation.title}
            </a>
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span>
              <span className="icon-bar"></span> <span className="icon-bar"></span>
            </button>
          </div>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            {navigation.links.map((link, i) => (
              <li key={`${link.title}-${i}`}>
                <a href={`${link.href}`} className="page-scroll">
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
