import Reaptcha from 'reaptcha'
import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

const ContactUs = props => {
  const data = useStaticQuery(graphql`
    {
      contacts: allContentfulContactUs {
        nodes {
          address
          id
          email
          phone
          paragraph
          title
          socialMediaIcons {
            name
            url
            icon
          }
        }
      }
    }
  `)
  const contact = data.contacts.nodes.find(item => item.id === props.contentfulId)

  const [serverState, setServerState] = useState({
    submitting: false,
    status: false,
    isOk: true,
    verified: false,
  })
  const handleServerResponse = (ok, status, form) => {
    setServerState({
      submitting: false,
      status: status,
      isOk: ok,
    })
    if (ok) {
      form.reset()
    }
  }
  const handleAlertDismiss = () => {
    setTimeout(() => {
      setServerState({ status: false})
    }, 5000)
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    fetch(process.env.GATSBY_FORM_DATA_SERVICE, {
      method: "post",
      body: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, true, form)
        handleAlertDismiss()
      })
      .catch(r => {
        handleServerResponse(false, true, form)
        handleAlertDismiss()
      })
  }

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{contact.title}</h2>
                <p>{contact.paragraph}</p>
              </div>
              <form name="sentMessage" id="contactForm" onSubmit={handleOnSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nombre"
                        required="required"
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Correo"
                        required="required"
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    name="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensaje"
                    required
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success">
                  {serverState.status ? (
                    serverState.isOk ? (
                      <div className="alert alert-success">
                        <strong>Gracias por enviarnos su mensaje</strong>
                      </div>
                    ) : (
                      <div className="alert alert-danger">
                        <strong>Parece que hubo un error al enviar el mensaje</strong>
                      </div>
                    )
                  ) : null}
                </div>
                <Reaptcha
                  sitekey="6Lf5e-oZAAAAAGdQaDm-1iqn8NsagM79YhWQSgki"
                  onVerify={e => {
                    setServerState({ verified: true })
                  }}
                />
                <button type="submit" className="btn btn-custom btn-lg" disabled={!serverState.verified}>
                  Enviar Mensaje
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Información de contacto</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Dirección
                </span>
                {contact.address}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Teléfono
                </span>
                {contact.phone}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Correo
                </span>
                {contact.email}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  {contact.socialMediaIcons.map((icon, i) => {
                    return (
                      <li key={`${icon.name}-${i}`}>
                        <a href={icon.url}>
                          <i className={`fa ${icon.icon}`}></i>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
