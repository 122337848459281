import React from "react"
import PropTypes from "prop-types"
import { withPrefix } from "gatsby"

export default function HTML(props) {
  return (
    <html {...props.htmlAttributes}>
      <head>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1 shrink-to-fit=no" />

        {props.headComponents}
      </head>
      <body {...props.bodyAttributes} id="page-top" data-spy="scroll" data-target=".navbar-fixed-top">
        {props.preBodyComponents}
        <div key={`body`} id="___gatsby" dangerouslySetInnerHTML={{ __html: props.body }} />
        {props.postBodyComponents}

        <script type="text/javascript" src={withPrefix("js/jquery.1.11.1.js")}></script>
        <script type="text/javascript" src={withPrefix("js/bootstrap.js")}></script>
        <script type="text/javascript" src={withPrefix("js/SmoothScroll.js")}></script>
        <script type="text/javascript" src={withPrefix("js/nivo-lightbox.js")}></script>
        <script type="text/javascript" src={withPrefix("js/jqBootstrapValidation.js")}></script>
        <script type="text/javascript" src={withPrefix("js/main.js")}></script>
        <script src="https://kit.fontawesome.com/4b9ba14b0f.js" crossOrigin="anonymous"></script>
      </body>
    </html>
  )
}

HTML.propTypes = {
  htmlAttributes: PropTypes.object,
  headComponents: PropTypes.array,
  bodyAttributes: PropTypes.object,
  preBodyComponents: PropTypes.array,
  body: PropTypes.string,
  postBodyComponents: PropTypes.array,
}
