import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Services = props => {
  const data = useStaticQuery(graphql`
    {
      serviceSections: allContentfulServicesSection {
        nodes {
          id
          title
          subtitle
          services {
            title
            paragraph
            icon {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
  `)
  const section = data.serviceSections.nodes.find(item => item.id === props.contentfulId)

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{section.title}</h2>
          <p>{section.subtitle}</p>
        </div>
        <div className="row">
          {section.services.map((service, i) => (
            <div key={`${service.title}-${i}`} className="col-md-4">
              <img className="icon" src={service.icon.file.url} alt={service.icon.title} />
              <div className="service-desc">
                <h3>{service.title}</h3>
                <p>{service.paragraph}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Services
