import React from "react"
import { Helmet } from "react-helmet"
import { withPrefix } from "gatsby"

const StylingHelmet = () => {
  return (
    <Helmet>
      <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Lato:400,700" rel="stylesheet" />
      <link
        href="https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800,900"
        rel="stylesheet"
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600;900&display=swap"
        rel="stylesheet"
      ></link>

      <link rel="preload" as="style" href={withPrefix("css/bootstrap.css")} />
      <link rel="preload" as="style" href={withPrefix("fonts/font-awesome/css/font-awesome.css")} />
      <link rel="preload" as="style" href={withPrefix("css/style.css")} />
      <link rel="preload" as="style" href={withPrefix("css/nivo-lightbox/nivo-lightbox.css")} />
      <link rel="preload" as="style" href={withPrefix("css/nivo-lightbox/default.css")} />

      <link rel="stylesheet" href={withPrefix("css/bootstrap.css")} />
      <link rel="stylesheet" href={withPrefix("fonts/font-awesome/css/font-awesome.css")} />
      <link rel="stylesheet" href={withPrefix("css/style.css")} />
      <link rel="stylesheet" href={withPrefix("css/nivo-lightbox/nivo-lightbox.css")} />
      <link rel="stylesheet" href={withPrefix("css/nivo-lightbox/default.css")} />
    </Helmet>
  )
}

export default StylingHelmet
