import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Gallery = props => {
  const data = useStaticQuery(graphql`
    {
      galleries: allContentfulGallery {
        nodes {
          id
          title
          subtitle
          images {
            title
            img {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
  `)
  const gallery = data.galleries.nodes.find(item => item.id === props.contentfulId)

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{gallery.title}</h2>
          <p>{gallery.subtitle}</p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {gallery.images.map((image, i) => (
              <div id={`gimage-${image.title}-${i}`} className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    <a href={image.img.file.url} title={image.title} data-lightbox-gallery="gallery1">
                      <div className="hover-text">
                        <h4>{image.title}</h4>
                      </div>
                      <img src={image.img.file.url} className="img-responsive" alt={image.title} />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery
